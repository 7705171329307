var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.reservation)?_c('div',{staticClass:"background-gray-bg h-full"},[_c('div',{staticClass:"d-flex flex-column align-center flex-grow-1",class:{ 'margin-x-9': !_vm.sm },style:({ height: '100%' })},[_c('TrackingAppBar',{attrs:{"reservation":_vm.reservation,"last-updated":_vm.lastUpdated}}),(_vm.status)?_c('TrackingStatus',{staticClass:"flex-grow-1",attrs:{"status":_vm.status}}):_c('section',{staticClass:"w-full d-flex justify-space-between flex-grow-1 flex-shrink-0 flex-wrap",class:{
        'padding-t-8 padding-b-12': !_vm.sm,
      },style:({ maxWidth: '1220px' })},[(!_vm.sm)?_c('div',{class:{
          'margin-r-15 flex-basis-0 flex-grow-1': _vm.lg,
          'max-w-632 w-full margin-x-auto': !_vm.lg,
        }},[_c('div',{staticClass:"w-full max-w-632 background-white rounded-xl padding-a-5 margin-t-5 box-shadow-medium"},[_c('TrackingCurrentStop',{key:"tracking-current-stop-lg-view",attrs:{"current-stop":_vm.currentStop,"current-journey":_vm.currentJourney,"last-tracking-point":_vm.lastTrackingPoint,"color":_vm.reservation.company.secondaryColor}}),_c('ReservationTrackingMap',{key:"tracking-map-lg-view",attrs:{"hide-styles":"","hide-tracking":"","show-bus":"","stops":_vm.reservation.trip.stops,"current-stop":_vm.currentStop.stop,"vehicles":_vm.mapVehicles,"selected-vehicle":_vm.selectedVehicle,"tracking":_vm.tracking,"color":_vm.reservation.company.primaryColor},on:{"vehicle-change":_vm.handleVehicleChange}})],1),_c('div',{staticClass:"w-full max-w-632 background-white rounded-xl padding-a-5 margin-t-5 box-shadow-medium"},[_c('p',{staticClass:"font-bold font-20 margin-b-3"},[_vm._v("Driver Info")]),_c('TrackingDriverInfo',{key:"tracking-driver-info-lg-view",staticClass:"w-full margin-b-n4",attrs:{"vehicle-assignments":_vm.reservation.vehicleAssignments}})],1)]):_c('ReservationTrackingMap',{key:"tracking-map-sm-view",style:({ height: `calc(${_vm.mobileScrollOffset}px - 50px)` }),attrs:{"mobile":"","hide-tracking":"","hide-styles":"","hide-rounded":"","hide-zoom-buttons":"","show-bus":"","color":_vm.reservation.company.primaryColor,"hide-vehicles":_vm.isAtTop !== 0,"stops":_vm.reservation.trip.stops,"current-stop":_vm.currentStop.stop,"vehicles":_vm.mapVehicles,"selected-vehicle":_vm.selectedVehicle,"tracking":_vm.tracking},on:{"vehicle-change":_vm.handleVehicleChange}}),_c('div',{ref:"tracking-mobile-scroll-div",class:{
          'max-w-632 w-full margin-x-auto': !_vm.lg,
          'position-absolute top-0 overflow-auto h-full': _vm.sm,
        },on:{"scroll":_vm.handleScroll}},[_c('div',{staticClass:"position-relative w-full max-w-632 background-white border-radius-top-right-20 border-radius-top-left-20 box-shadow-medium transition-ease transition-duration-300",class:{
            'rounded-xl margin-t-5 padding-a-5': !_vm.sm,
            'padding-b-16 padding-t-2 padding-x-5 z-6': _vm.sm,
          },style:({ top: _vm.sm ? `${_vm.mobileScrollOffset}px` : '' })},[(_vm.sm)?_c('TrackingCurrentStop',{key:"tracking-current-stop-sm-view",attrs:{"dense":"","current-stop":_vm.currentStop,"current-journey":_vm.currentJourney,"last-tracking-point":_vm.lastTrackingPoint,"color":_vm.reservation.company.secondaryColor}}):_vm._e(),(_vm.sm)?_c('TrackingDriverInfo',{key:"tracking-driver-info-sm-view",staticClass:"w-full",attrs:{"dense":"","hide-vehicles":_vm.isAtTop === 0,"vehicle-assignments":_vm.displayVehicles,"color":_vm.reservation.company.primaryColor}}):_vm._e(),(_vm.reservation.vehicleAssignments.length && _vm.sm)?_c('v-divider',{staticClass:"margin-b-4"}):_vm._e(),_c('p',{staticClass:"font-bold font-20 margin-b-3"},[_vm._v("Trip Details")]),_c('CheckoutTripDetails',{attrs:{"hide-totals":"","trip":_vm.reservation.trip,"addresses":_vm.tripAddresses,"color":_vm.reservation.company.secondaryColor,"index":1}})],1)])],1),(!_vm.sm)?_c('Footer',{style:({ maxWidth: '1220px' }),attrs:{"company":_vm.reservation.company}}):_vm._e()],1)]):_c('Loader')
}
var staticRenderFns = []

export { render, staticRenderFns }