var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"w-full",class:{ 'h-160': _vm.$vuetify.breakpoint.width >= 1200 }},[_c('div',{class:{ 'margin-x-15': _vm.$vuetify.breakpoint.width < 1200 }},[_c('v-divider',{staticClass:"margin-b-6"})],1),_c('div',{staticClass:"d-flex justify-space-between align-center",class:{
      'max-w-320 margin-x-auto margin-b-3': _vm.$vuetify.breakpoint.width < 1200,
    }},[_c('div',{class:{ 'cursor-pointer z-1': _vm.hasWebsiteUrl },on:{"click":_vm.openWebsiteLink}},[_c('img',{staticClass:"max-w-152 max-h-52",attrs:{"src":_vm.staticResource(_vm.company.darkLogoUrl)}})]),_c('div',{staticClass:"d-flex w-120 justify-end"},[(_vm.twitterLink)?_c('a',{staticClass:"z-10 margin-l-5",attrs:{"href":_vm.twitterLink,"target":"_blank"}},[_c('CUIcon',{attrs:{"width":"24px","height":"24px"}},[_vm._v("twitter")])],1):_vm._e(),(_vm.facebookLink)?_c('a',{staticClass:"z-10 margin-l-5",attrs:{"href":_vm.facebookLink,"target":"_blank"}},[_c('CUIcon',{attrs:{"width":"24px","height":"24px"}},[_vm._v("facebook")])],1):_vm._e(),(_vm.instagramLink)?_c('a',{staticClass:"z-10 margin-l-5",attrs:{"href":_vm.instagramLink,"target":"_blank"}},[_c('CUIcon',{attrs:{"width":"24px","height":"24px"}},[_vm._v("instagram")])],1):_vm._e()])]),_c('div',{staticClass:"position-relative margin-b-4",class:{
      'top-n20 padding-t-2 min-h-92': _vm.$vuetify.breakpoint.width >= 1200,
      'd-flex justify-center align-center flex-wrap margin-l-152 margin-r-120':
        _vm.$vuetify.breakpoint.width >= 650,
      'w-336 margin-x-auto margin-t-6': _vm.$vuetify.breakpoint.width < 650,
    },style:(_vm.$vuetify.breakpoint.width >= 650
        ? { marginLeft: '152px', marginRight: '120px' }
        : {})},[_c('div',{staticClass:"padding-x-3 d-flex align-center padding-y-2"},[_c('CUIcon',{staticClass:"margin-r-2 flex-shrink-0",attrs:{"width":"20px","height":"20px","color":"gray-icon-dark"}},[_vm._v(" phone ")]),_c('span',[_vm._v(_vm._s(_vm.phoneFormatFilter(_vm.company.phone)))])],1),_c('div',{staticClass:"padding-x-3 d-flex align-center padding-y-2"},[_c('CUIcon',{staticClass:"margin-r-2 flex-shrink-0",attrs:{"width":"20px","height":"20px","color":"gray-icon-dark"}},[_vm._v(" mail ")]),_c('span',[_vm._v(_vm._s(_vm.company.email))])],1),_c('div',{staticClass:"padding-x-3 d-flex align-top padding-y-2"},[_c('CUIcon',{staticClass:"margin-r-2 flex-shrink-0",attrs:{"width":"20px","height":"20px","color":"gray-icon-dark"}},[_vm._v(" place ")]),_c('span',[_vm._v(_vm._s(_vm.company.address.name || _vm.company.address.addressName))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }