
import { Vue, Component, Prop } from 'vue-property-decorator'
import { httpHelper, phoneFormatFilter } from '@/utils/string'
import { staticResource } from '@/utils/env'
import { Reservation } from '@/models/dto'
import colors from '@/scss/_colors-export.scss'

@Component({})
export default class TrackingAppBar extends Vue {
  @Prop({ required: true }) reservation: Reservation
  @Prop({ required: true }) lastUpdated: string

  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource

  get color(): string {
    return this.reservation.company.primaryColor || colors['black']
  }

  get hasWebsiteUrl(): boolean {
    return !!this.reservation?.company?.websiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.reservation.company.websiteUrl), '_blank')
    }
  }
}
