var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex flex-column justify-center align-center margin-t-29 margin-b-10 padding-x-3"},[_c('div',{staticClass:"rounded-circle d-flex align-center justify-center margin-b-3",class:{
        'background-green-10': _vm.isSuccess,
        'background-rust-10': !_vm.isSuccess,
      },style:({
        width: '70px',
        height: '70px',
      })},[(_vm.isSuccess)?_c('CUIcon',{staticClass:"margin-t-3 margin-l-2",attrs:{"aria-label":"Success","width":"72px","height":"52px","color":"green"}},[_vm._v(" confirm ")]):_c('CUIcon',{attrs:{"aria-label":"Error","width":"34px","height":"34px","color":"rust"}},[_vm._v(" alarm_clock ")])],1),_c('h1',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.isSuccess ? 'Tracking has ended for this trip.' : 'There was an error loading tracking.')+" ")]),_c('p',{staticClass:"font-16 margin-t-2 margin-b-0"},[_vm._v(" Please contact us for additional information or help ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }