
import { VehicleAssignment } from '@/models/dto'
import colors from '@/scss/_colors-export.scss'
import {
  phoneFormatFilter,
  formatFullNameWithFirstLetterOfLastName,
} from '@/utils/string'
import { getVehicleMakeAndLicense } from '@/utils/vehicle'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TrackingDriverInfo extends Vue {
  @Prop({ required: true }) readonly vehicleAssignments: VehicleAssignment[]
  @Prop({ required: false, default: colors['black'] }) readonly color: string
  @Prop({ type: Boolean, default: false }) readonly dense: boolean
  @Prop({ type: Boolean, default: false }) readonly hideVehicles: boolean

  get assignmentMap(): { vehicle: string; driver: string; phone: string }[] {
    return this.vehicleAssignments.reduce((arr, assignment) => {
      const vehicle = assignment.isConfirmed
        ? getVehicleMakeAndLicense(assignment.vehicle)
        : assignment.vehicleType.label
      let temp = (assignment.driverAssignments || []).map((driver) => ({
        vehicle: null,
        driver: driver.isConfirmed
          ? formatFullNameWithFirstLetterOfLastName(driver.driver)
          : null,
        phone: driver.isConfirmed
          ? phoneFormatFilter(driver.driver.phone.toString())
          : null,
      }))
      if (temp.length) {
        temp[0].vehicle = vehicle
      } else {
        temp = [{ vehicle, driver: null, phone: null }]
      }
      arr.push(...temp)
      return arr
    }, [])
  }
}
