
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TrackingStatus extends Vue {
  @Prop({ required: true }) status: 'error' | 'finished'

  get isSuccess(): boolean {
    return this.status === 'finished'
  }
}
