
import { Address, Stop, Trip } from '@/models/dto'
import { Journey, JourneyStop } from '@/models/dto/Journey'
import { Quote } from '@/models/dto/Quote'
import { TrackingJourneyData } from '@/models/dto/Tracking'
import widget from '@/services/widget'
import dayjs from 'dayjs'
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

// Updates every 20 minutes
const ESTIMATE_DELAY = 240

@Component({})
export default class TrackingCurrentStop extends mixins(DateMixin) {
  @Prop({ required: true }) readonly currentJourney: Journey
  @Prop({ required: true }) readonly currentStop: JourneyStop
  @Prop({ required: true }) readonly color: string
  @Prop({ type: Boolean, default: false }) readonly dense: boolean
  @Prop({ required: false, default: null })
  readonly lastTrackingPoint: TrackingJourneyData

  nextEstimatedArrival = {}
  estimateTimer = 0

  @Watch('lastTrackingPoint', { immediate: true })
  async handleUpdateLastTrackingPoint(
    point: TrackingJourneyData
  ): Promise<void> {
    if (
      this.estimateTimer !== ESTIMATE_DELAY &&
      !!this.nextEstimatedArrival[this.currentStop.journeyStopId]
    ) {
      this.estimateTimer += 1
      return
    }
    if (!point || !this.currentStop || this.currentStop.reached) {
      this.nextEstimatedArrival[this.currentStop.journeyStopId] = null
      return
    }
    const estimation = await widget.tripEstimations(
      this.getQuoteForEstimation(point)
    )

    const estimationData = estimation?.data?.[0]?.timesFromPreviousStop
    this.$set(
      this.nextEstimatedArrival,
      this.currentStop.journeyStopId,
      dayjs(point.receivedDate)
        .tz(this.currentStop.stop.address.timeZone)
        .add(estimationData[estimationData.length - 1], 'seconds')
        .toISOString()
    )

    this.estimateTimer = 0
    return
  }

  get currentStopIsSequential(): boolean {
    return (
      !!this.currentStop.stop.pickupDatetime &&
      !!this.currentStop.stop.dropoffDatetime
    )
  }

  get stopInfo(): { dateLabel: string; typeLabel: string; address: string } {
    const { pickupDatetime, dropoffDatetime, address } = this.currentStop.stop
    const isXs = this.$vuetify.breakpoint.width < 395
    let stopTypeLabel = ''
    let date = null

    if (this.currentStopIsSequential) {
      if (this.currentStop.reached) {
        stopTypeLabel = 'Pickup'
        date = pickupDatetime
      } else {
        stopTypeLabel = 'Dropoff'
        date = dropoffDatetime
      }
    } else {
      stopTypeLabel = pickupDatetime ? 'Pickup' : 'Dropoff'
      date = pickupDatetime || dropoffDatetime
    }

    const estimate = this.nextEstimatedArrival[this.currentStop.journeyStopId]
    date = estimate || date
    let dateLabel = date
      ? ` ${this.formatShortTime(date, {
          tz: address.timeZone,
          showMeridianLower: true,
        })}`
      : ''
    if (isXs) {
      dateLabel = estimate ? `Est. ${dateLabel}` : dateLabel
    } else {
      dateLabel = estimate ? `Estimated ${dateLabel}` : dateLabel
    }
    return {
      dateLabel: `${dateLabel}`,
      typeLabel: `${stopTypeLabel}`,
      address: address.title || address.city,
    }
  }

  get stopStatus(): string {
    if (!this.currentJourney.isStarted) {
      return 'Upcoming'
    }

    if (this.currentJourney.finished) {
      return 'Completed'
    }

    const { completed, reached } = this.currentStop

    // For sequential stops:
    // - If they've been reached, but haven't been departed yet, status is Upcoming
    // - If they haven't been reached or departed yet, status is En Route
    // - If they have been both reached and departed, status is 'Completed' (shouldn't be possible to hit)
    if (this.currentStopIsSequential) {
      if (completed) {
        return 'Completed'
      } else if (reached) {
        return 'Upcoming'
      } else {
        return 'En Route'
      }
    }

    // For single stops:
    // - If they've been reached, then they've been completed
    // - If they haven't been reached, then they're en route
    return completed ? 'Completed' : 'En Route'
  }

  get statusColor(): { foreground: string; background: string } {
    if (this.stopStatus === 'En Route') {
      return { foreground: 'additional-blue', background: 'additional-blue-2' }
    } else if (this.stopStatus === 'Completed') {
      return { foreground: 'green', background: 'green-10' }
    } else {
      return { foreground: 'orange', background: 'orange-10' }
    }
  }

  get lightenedColor(): string {
    const color = this.color || '#000000'
    return color.includes('#') ? `${color}1a` : color
  }

  getQuoteForEstimation(start: TrackingJourneyData): Quote {
    return new Quote({
      trips: [
        new Trip({
          stops: [
            new Stop({
              orderIndex: 0,
              address: new Address({
                lat: parseFloat(start.lat),
                lng: parseFloat(start.lng),
              }),
              pickupDatetime: start.receivedDate,
            }),
            new Stop({
              orderIndex: 1,
              address: this.currentStop.stop.address,
            }),
          ],
        }),
      ],
    })
  }
}
