
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Company, SocialLink } from '@/models/dto/Company'
import { httpHelper, phoneFormatFilter } from '@/utils/string'
import { baseUrl, staticResource } from '@/utils/env'
import { findLink } from '@/utils/socialLink'

@Component({})
export default class CheckoutFooter extends Vue {
  @Prop({ required: true }) readonly company: Company

  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource
  baseUrl = baseUrl

  get facebookLink(): string {
    const link = findLink('facebook', this.company?.socialLinks)
    return httpHelper(link?.uri)
  }

  get instagramLink(): string {
    const link = findLink('instagram', this.company?.socialLinks)
    return httpHelper(link?.uri)
  }

  get twitterLink(): string {
    const link = findLink('twitter', this.company?.socialLinks)
    return httpHelper(link?.uri)
  }

  get hasWebsiteUrl(): boolean {
    return !!this.company?.websiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.company?.websiteUrl), '_blank')
    }
  }
}
