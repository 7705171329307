
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Trip, TripNote } from '@/models/dto/Trip'
import {
  cleanEmptyHtml,
  countryPhoneFormatFilter,
  currencyFilter,
  formatFullName,
} from '@/utils/string'
import StopWithNotes from '@/components/StopWithNotes.vue'
import { stopTitle } from '@/utils/stop'
import { LineItemSectionTypes, TripNoteTypes } from '@/utils/enum'
import {
  getTripSubtotal,
  getTripTotal,
  getTripTotalBaseFare,
} from '@/utils/trip'
import {
  lineItemChargeAmount,
  lineItemChargesBySectionType,
} from '@/utils/charge'
import { LineItemCharge } from '@/models/dto/LineItemCharge'
import dayjs from 'dayjs'

@Component({
  components: {
    StopWithNotes,
  },
})
export default class CheckoutTripDetails extends mixins(DateMixin) {
  @Prop({ required: true }) readonly trip!: Trip
  @Prop({ required: true }) readonly addresses!: string[]

  @Prop({ required: false, type: String, default: '#000000' })
  readonly color!: string

  @Prop({ required: true }) readonly index: number
  @Prop({ type: Boolean, required: false }) readonly hideTotals: boolean

  currencyFilter = currencyFilter
  formatFullName = formatFullName
  countryPhoneFormatFilter = countryPhoneFormatFilter
  lineItemChargeAmount = lineItemChargeAmount

  get lightenedColor(): string {
    const color = this.color || '#000000'
    return color.includes('#') ? `${color}1a` : color
  }

  get notes(): TripNote[] {
    return this.trip.tripNotes.filter(
      (n) =>
        n.noteType === TripNoteTypes.TRIP_NOTE_TYPE && !!cleanEmptyHtml(n.html)
    )
  }

  get tripTotal(): number {
    return getTripTotal(this.trip)
  }

  get tripSubtotal(): number {
    return getTripSubtotal(this.trip)
  }

  get getTripTotalBaseFare(): number {
    return getTripTotalBaseFare(this.trip)
  }

  get itemizedCharges(): LineItemCharge[] {
    return lineItemChargesBySectionType(
      this.trip,
      LineItemSectionTypes.ITEMIZED_CHARGE
    )
  }

  get taxes(): LineItemCharge[] {
    const taxes = lineItemChargesBySectionType(
      this.trip,
      LineItemSectionTypes.SUBTOTAL
    )
    return taxes || []
  }

  get recurrenceString(): string {
    return this.trip?.recurrenceTripCount > 1
      ? `${this.trip.routeName || 'Trip'} Total (${
          this.trip.recurrenceTripCount
        } recurrences)`
      : null
  }

  get display(): {
    showTz: boolean
    addressMinWidth: string
    stopMinWidth: string
  } {
    const initalTz = this.trip.stops[0].address?.timeZone
    const showTz = !this.trip.stops.every(
      (s) => s.address?.timeZone === initalTz
    )
    const hasSequentialAndSpotTime = this.trip.stops.some(
      (s) => s.pickupDatetime && s.dropoffDatetime && s.spotTime?.spotTime
    )
    const hasSequential = this.trip.stops.some(
      (s) => s.pickupDatetime && s.dropoffDatetime
    )
    const hasSpotTime = this.trip.stops.some((s) => !!s.spotTime?.spotTime)

    if (hasSequentialAndSpotTime) {
      return {
        showTz,
        addressMinWidth: showTz ? '220px' : '255px',
        stopMinWidth: showTz ? '290px' : '265px',
      }
    } else if (hasSequential) {
      return {
        showTz,
        addressMinWidth: showTz ? '290px' : '315px',
        stopMinWidth: showTz ? '220px' : '205px',
      }
    } else if (hasSpotTime) {
      return {
        showTz,
        addressMinWidth: showTz ? '320px' : '360px',
        stopMinWidth: showTz ? '190px' : '160px',
      }
    } else {
      return {
        showTz,
        addressMinWidth: '395px',
        stopMinWidth: '115px',
      }
    }
  }

  getStopTitle(i: number): string {
    return stopTitle(this.trip?.stops[i])
  }

  getSpotTime(i: number): string {
    if (!this.trip.stops[i]?.spotTime?.spotTime) {
      return null
    }

    return this.formatShortTime(
      this.trip.stops[i].spotTime.spotTime.toString(),
      {
        tz: this.trip.stops[i].address.timeZone,
        showMeridianUpper: true,
      }
    )
  }

  formatDayTime(date: string, tz: string): string {
    const shortDate = this.formatShortDate(date, { tz })
    const day = dayjs(date).tz(tz).format('ddd')
    return `${day}, ${shortDate}`
  }
}
