
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import TrackingAppBar from '@/components/TrackingAppBar.vue'
import reservationClient from '@/services/reservation'
import trackingClient from '@/services/tracking'
import Loader from '@/components/CheckoutLoader.vue'
import Footer from '@/components/CheckoutFooter.vue'
import app from '@/store/modules/app'
import { ReservationStatus } from '@/utils/enum'
import TrackingStatus from '@/components/TrackingStatus.vue'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import TrackingDriverInfo from '@/components/TrackingDriverInfo.vue'
import TrackingCurrentStop from '@/components/TrackingCurrentStop.vue'
import CheckoutTripDetails from '@/components/CheckoutTripDetails.vue'
import { Reservation, VehicleAssignment } from '@/models/dto'
import { Journey, JourneyStop } from '@/models/dto/Journey'
import { TrackingJourneyData } from '@/models/dto/Tracking'
import dayjs from 'dayjs'
import { useFavicon } from '@vueuse/core'
import { isDevEnvironment, staticResource } from '@/utils/env'
import { getVehicleMakeAndLicense } from '@/utils/vehicle'
import auth from '@/store/modules/auth'

@Component({
  components: {
    TrackingAppBar,
    TrackingDriverInfo,
    TrackingCurrentStop,
    ReservationTrackingMap,
    Loader,
    Footer,
    TrackingStatus,
    CheckoutTripDetails,
  },
})
export default class Tracking extends mixins(DateMixin) {
  reservation = null
  tracking = null
  status: 'error' | 'finished' = null
  selectedVehicle: { label: string; id: number; event: string } = null
  isAtTop = 0
  debounce = null
  lastUpdated = null
  trackingIntervalId = null
  reservationIntervalId = null
  reservationHash = null

  get sm(): boolean {
    return this.$vuetify.breakpoint.width < 632
  }

  get lg(): boolean {
    return this.$vuetify.breakpoint.width >= 1200
  }

  get tripAddresses(): string[] {
    return this.reservation.trip.stops.map((stop) => stop.address.name)
  }

  get mapVehicles(): { label: string; id: number; event: string }[] {
    const map =
      this.reservation?.vehicleAssignments.map(({ vehicle: v }) => ({
        label: getVehicleMakeAndLicense(v),
        id: v.vehicleId,
        event: 'input',
      })) || []
    this.selectedVehicle = this.selectedVehicle || map[0]
    return map
  }

  get displayVehicles(): VehicleAssignment[] {
    const assignments = [...this.reservation.vehicleAssignments]
    const el = assignments.find(
      (a) => a.vehicle.vehicleId === this.selectedVehicle?.id
    )
    assignments.splice(assignments.indexOf(el), 1)
    assignments.unshift(el)
    return assignments
  }

  get mobileScrollOffset(): number {
    const numDrivers =
      this.reservation.vehicleAssignments.find(
        (v) => v.vehicle.vehicleId === this.selectedVehicle?.id
      )?.driverAssignments.length || 1
    return window.innerHeight - (92 + 54.5 + 42 * (numDrivers - 1))
  }

  get selectedVehicleId(): number {
    return this.selectedVehicle?.id
  }

  get currentJourney(): Journey {
    const matchingJourneys = this.tracking.journeys
      .filter((journey) => journey.vehicleId === this.selectedVehicleId)
      .sort((a, b) => (a.journeyId > b.journeyId ? 1 : -1))

    if (!matchingJourneys.length) {
      return this.tracking.journeys[0]
    }

    const activeJourneys = matchingJourneys.filter(({ finished }) => !finished)
    if (!activeJourneys.length) {
      return matchingJourneys[matchingJourneys.length - 1]
    }

    return activeJourneys[activeJourneys.length - 1]
  }

  get currentStop(): JourneyStop {
    const { journeyStops } = this.currentJourney
    const stops = journeyStops
      .map((stop) => ({
        ...stop,
        completed: this.isStopCompleted(stop),
      }))
      .sort((a, b) => (a.stop.orderIndex > b.stop.orderIndex ? 1 : -1))
    const uncompletedStops = stops.filter(({ completed }) => !completed)

    if (uncompletedStops.length && !this.currentJourney.finished) {
      return uncompletedStops[0]
    } else {
      return stops[stops.length - 1]
    }
  }

  isStopCompleted(stop: JourneyStop): boolean {
    if (stop.stop.pickupDatetime && stop.stop.dropoffDatetime) {
      return !!stop.reached && !!stop.isDeparted
    }
    return !!stop.reached
  }

  handleVehicleChange(vehicle: {
    label: string
    id: number
    event: string
  }): void {
    this.selectedVehicle = vehicle
  }

  handleScroll(e: any): void {
    this.isAtTop = e.target.scrollTop

    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(() => {
      if (this.isAtTop < 35) {
        this.$nextTick(() => {
          const el: any = this.$refs['tracking-mobile-scroll-div']
          el.scrollTo({ behavior: 'smooth', top: 0 })
        })
      }
    }, 200)
  }

  get lastTrackingPoint(): TrackingJourneyData {
    const data = [...(this.tracking?.trackingJourneyDataList || [])]
    data.filter(({ vehicleId }) => !!vehicleId).reverse()
    const gpsData = data.find(
      ({ vehicleId }) => vehicleId === this.selectedVehicleId
    )?.gpsData

    if (gpsData?.length && this.currentStop?.stop?.address) {
      return gpsData[gpsData.length - 1]
    }
    return null
  }

  async refreshTrackingData(): Promise<void> {
    try {
      const { data: trackingData } = await trackingClient.byReservationHash(
        this.reservationHash
      )
      this.tracking = trackingData
      this.lastUpdated = this.formatShortTime(dayjs().toISOString(), {
        showMeridianLower: true,
      })
      if (!this.tracking.journeys.length) {
        this.setReservationError()
      }
    } catch (e) {
      this.setReservationError()
    }
  }

  async refreshReservationData(): Promise<void> {
    try {
      const {
        data: { reservation },
      } = await reservationClient.byHash(this.reservationHash)
      this.reservation = reservation
      if (this.isReservationFinished(reservation)) {
        this.setReservationFinished()
      } else {
        this.status = null
      }
    } catch (e) {
      this.setReservationError()
    }
  }

  async load(): Promise<void> {
    try {
      const r = reservationClient.byHash(this.reservationHash)
      const t = trackingClient.byReservationHash(this.reservationHash)
      const [reservation, tracking] = await Promise.all([r, t])
      this.reservation = reservation.data.reservation
      this.tracking = tracking.data
      this.lastUpdated = this.formatShortTime(dayjs().toISOString(), {
        showMeridianLower: true,
      })

      window.document.title = `Res ${this.reservation.managedId} Tracking - ${this.reservation.company.name}`
      let faviconUrl =
        this.reservation?.company.faviconUrl ||
        this.reservation?.company.darkLogoUrl
      if (faviconUrl) {
        const icon = useFavicon()
        faviconUrl = staticResource(faviconUrl)
        icon.value = faviconUrl
      }

      if (this.isReservationFinished(this.reservation)) {
        this.setReservationFinished()
      } else {
        this.status = null
      }

      if (!this.tracking.journeys.length) {
        this.setReservationError()
      }
    } catch (e) {
      console.error(e)
      this.setReservationError()
    }
  }

  setReservationFinished(): void {
    this.status = 'finished'
    clearInterval(this.trackingIntervalId)
    clearInterval(this.reservationIntervalId)
  }

  setReservationError(): void {
    this.status = 'error'
    clearInterval(this.trackingIntervalId)
    clearInterval(this.reservationIntervalId)
  }

  isReservationFinished(reservation: Reservation): boolean {
    if (
      reservation.reservationStatus === ReservationStatus.Completed ||
      !reservation.company?.defaultEnableTrackingLink ||
      !reservation.enableTrackingLink
    ) {
      return true
    }
    return false
  }

  async mounted(): Promise<void> {
    if (!isDevEnvironment() && !app.isCustomersEnvironment) {
      this.$router.push({ name: 'home' })
    }

    if (!this.$route.params.id) {
      this.status = 'error'
      return
    }

    this.reservationHash = this.$route.params.id

    await this.load()
    if (!this.status) {
      this.trackingIntervalId = setInterval(
        () => this.refreshTrackingData(),
        5000
      )
      this.reservationIntervalId = setInterval(
        () => this.refreshReservationData(),
        45000
      )
    }

    if (this.reservation?.company) {
      auth.setShouldDisplayDayLeading(
        this.reservation.company.defaultDateFormat
      )
    }
  }
}
