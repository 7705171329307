import { SocialLink } from '@/models/dto/Company'

export const findLink = (
  type: string,
  socialLinks: SocialLink[]
): SocialLink => {
  return (
    socialLinks?.find((link) => {
      return link.typeKey === type || link.socialMediaType?.key === type
    }) || null
  )
}
